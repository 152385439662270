import gql from 'graphql-tag';

export const SSRLabelsQuery = gql`
	query SSRLabelsQuery($contentId: ID!) {
		content(id: $contentId) {
			nodes {
				id
				type
				status
				operations {
					operation
					targetType
				}
				space {
					id
				}
				labels(orderBy: { sortField: LABELLING_CREATIONDATE, direction: ASCENDING }) {
					count
					nodes {
						id
						label
						name
						prefix
					}
				}
			}
		}
	}
`;
