import { useContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useLazyQuery, useMutation } from 'react-apollo';
import { useIntl, defineMessages } from 'react-intl-next';
import uuid from 'uuid/v4';
import unionBy from 'lodash/unionBy';
import type { ApolloError, MutationUpdaterFn } from 'apollo-client';
import debounce from 'lodash/debounce';

import { createFilter } from '@atlaskit/select';
import type { GroupedOptionsType } from '@atlaskit/select';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	LABELS_SEARCH_LABEL_EXPERIENCE,
	LABELS_RECOMMENDED_EXPERIENCE,
	LABELS_ADD_LABEL_EXPERIENCE,
	LABELS_CREATE_LABEL_EXPERIENCE,
	LABELS_DELETE_LABEL_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useSessionData } from '@confluence/session-data';
import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { fg } from '@confluence/feature-gating';
import { isViewPage, isBlogPage } from '@confluence/named-routes';

import { parseErrorMessage } from '../helpers';
import { ALLOWED_CONTENT_STATUSES, ActionTypes, INVALID_CHARACTERS } from '../constants';

import { LabelSearchQuery as LabelsSelectSearchQuery } from './LabelsSearchQuery.graphql';
import { AddLabelsMutation as LabelsSelectAddLabelsMutation } from './AddLabelsMutation.graphql';
import { DeleteLabelMutation as LabelsSelectDeleteLabelsMutation } from './DeleteLabelMutation.graphql';
import type {
	LabelSearchQuery as LabelSearchQueryType,
	LabelSearchQueryVariables,
} from './__types__/LabelSearchQuery';
import type { DeleteLabelMutation } from './__types__/DeleteLabelMutation';
import { LabelsQuery } from './LabelsQuery.graphql';
import { SSRLabelsQuery } from './SSRLabelsQuery.graphql';
import type { CustomOptionType } from './LabelsSelect';
import type { LabelsQuery as LabelsQueryType, LabelsQueryVariables } from './__types__/LabelsQuery';
import type {
	SSRLabelsQuery as SSRLabelsQueryType,
	SSRLabelsQueryVariables,
} from './__types__/SSRLabelsQuery';
import { RecommendedLabelsQuery } from './RecommendedLabelsQuery.graphql';
import type {
	RecommendedLabelsQuery as RecommendedLabelsQueryType,
	RecommendedLabelsQueryVariables,
} from './__types__/RecommendedLabelsQuery';

const i18n = defineMessages({
	suggestedLabels: {
		id: 'labels.use-labels-select-props.suggested-labels',
		defaultMessage: 'Suggested labels',
		description:
			'heading for options in labels select that are recommended by smarts label service',
	},
});

const getValidationError = (inputValue: string) => {
	const parsedInputValue = inputValue.replace(/^my:/, '');
	if (parsedInputValue.match(INVALID_CHARACTERS)) {
		// list taken from monolith here: com/atlassian/confluence/labels/LabelParser.java:39
		return 'labelsValidationInvalidCharacter';
	} else if (parsedInputValue.match(/^~/)) {
		return 'labelsValidationTilde';
	}
	if (parsedInputValue.length > 255) {
		return 'labelsValidationLength';
	}
	return '';
};

const isLabelNotFoundError = (error) =>
	/No label found with name/.test(parseErrorMessage(error).message);

const isContentNotFoundError = (error) =>
	/No content found with id/.test(parseErrorMessage(error).message);

const isLabelDeletePermissionsError = (error) =>
	/Could not delete label from content with id/.test(parseErrorMessage(error).message);

const isLabelMutationLoggedOutError = (error) =>
	/Current user not permitted to use Confluence/.test(parseErrorMessage(error).message);

const isLabelSearch403Error = (error) =>
	/Unexpected status code 403/.test(parseErrorMessage(error).message);

export const getPrivacySafePrefix = (prefix = '') => prefix.match(/my|global|team/)?.[0];

export const isStarredLabel: (label: string) => boolean = (label) =>
	label === 'my:favourite' || label === 'my:favorite';

const filterByPrefix = (labels, prefix) =>
	(labels || []).filter((label) => label?.prefix === prefix);

const transformToPrefixedLabel = (prefix, label) =>
	`${prefix && prefix !== 'global' ? `${prefix}:` : ''}${label}`;

const sanitizeInputValue = (input: string) => {
	return input.toLowerCase().replace(/\s/g, '-');
};

const getRecommendedLabelsFromData = (data) => data?.getRecommendedLabels?.recommendedLabels;

const makeTransformLabelToOption = (strategy) => (label) => {
	const prefixedLabel = transformToPrefixedLabel(label.prefix, label.label);
	return {
		id: label.id,
		label: prefixedLabel,
		value: prefixedLabel,
		prefix: label.prefix,
		name: label.name,
		strategy,
	};
};

const transformRecommendedLabelToOption = (label) => {
	const prefixedLabel = transformToPrefixedLabel(label.namespace, label.name);
	return {
		id: label.id,
		label: prefixedLabel,
		value: prefixedLabel,
		prefix: label.namespace,
		name: label.name,
		strategy: 'recommended',
		subStrategy: label.strategy,
	};
};

const transformCurrentLabelsToSelectedOptions = (currentLabels): CustomOptionType[] => {
	if (!currentLabels) {
		return [];
	}
	return (currentLabels?.content?.nodes?.[0]?.labels?.nodes || [])
		.map(makeTransformLabelToOption('current'))
		.filter(({ label }) => !isStarredLabel(label));
};

const useOptions = ({ searchLabelQueryResults, recommendedLabelsQueryResults, hasInput }) => {
	const intl = useIntl();
	return useMemo(() => {
		if (hasInput) {
			const otherLabelOptions = (
				searchLabelQueryResults?.contentLabelSearch?.otherLabels || []
			).map(makeTransformLabelToOption('searched_other'));

			const suggestedLabelOptions = (
				searchLabelQueryResults?.contentLabelSearch?.suggestedLabels || []
			).map(makeTransformLabelToOption('searched_suggested'));
			return [...suggestedLabelOptions, ...otherLabelOptions];
		} else {
			const recommendedLabelOptions = (
				getRecommendedLabelsFromData(recommendedLabelsQueryResults) || []
			).map(transformRecommendedLabelToOption);
			return [
				{
					label: intl.formatMessage(i18n.suggestedLabels),
					options: recommendedLabelOptions,
				},
			];
		}
	}, [searchLabelQueryResults, recommendedLabelsQueryResults, hasInput, intl]);
};

type LabelsSelectPropsType = ({
	contentId,
	source,
	onUpdateSuccess,
	onAdded,
}: {
	contentId: string;
	source: string;
	onUpdateSuccess?: () => void;
	onAdded?: () => void;
}) => {
	options: GroupedOptionsType<CustomOptionType>;
	filterOption: (option, inputValue: string) => boolean;
	value: CustomOptionType[];
	onChange: (label: any, action: any) => any;
	onMenuOpen: () => void;
	onInputChange: (inputValue: string) => void;
	inputValue: string;
	isLoading: boolean;
	isDisabled?: boolean;
	labelsQueryLoading: boolean;
	labelsQueryError?: ApolloError;
	labelsMutationError?: ApolloError;
	validationError: string;
	labelsSearchQueryError?: ApolloError;
	fetchRecommendedLabels: () => void;
};

export const useLabelsSelectProps: LabelsSelectPropsType = ({
	contentId,
	source,
	onUpdateSuccess,
	onAdded,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isLivePage = useIsCurrentPageLive();

	const experienceTracker = useContext(ExperienceTrackerContext);
	const { activationId } = useSessionData();
	const [validationError, setValidationError] = useState('');
	const [inputValue, setInputValue] = useState('');
	const isLabelsPreloadedFromContent = Boolean(
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		((window.__SSR_RENDERED__ && (isViewPage() || isBlogPage())) || process.env.REACT_SSR) &&
			fg('confluence_frontend_new_preload_labels'),
	);
	const isLabelsPreloaded = Boolean(
		window.__SSR_RENDERED__ && fg('confluence_frontend_preload_labels'),
	);

	const {
		data: ssrLabelsQueryData,
		loading: ssrLabelsQueryLoading,
		error: ssrLabelsQueryError,
	} = useQuery<SSRLabelsQueryType, SSRLabelsQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SSRLabelsQuery,
		{
			variables: {
				contentId,
			},
			skip: !isLabelsPreloadedFromContent,
		},
	);

	const {
		data: labelsQueryData,
		loading: labelsQueryLoading,
		error: labelsQueryError,
	} = useQuery<LabelsQueryType, LabelsQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		LabelsQuery,
		{
			variables: {
				contentId,
				refetchQuery: false,
				status: ALLOWED_CONTENT_STATUSES,
			},
			fetchPolicy: isLabelsPreloaded ? undefined : 'cache-and-network',
			skip: isLabelsPreloadedFromContent,
		},
	);

	const labelData = useMemo(() => {
		if (isLabelsPreloadedFromContent) {
			if (!ALLOWED_CONTENT_STATUSES.includes(ssrLabelsQueryData?.content?.nodes?.[0]?.status || ''))
				return null;
			return ssrLabelsQueryData;
		}
		return labelsQueryData;
	}, [ssrLabelsQueryData, labelsQueryData, isLabelsPreloadedFromContent]);

	const selectedOptions = useMemo(
		() => transformCurrentLabelsToSelectedOptions(labelData),
		[labelData],
	);

	const type = labelData?.content?.nodes?.[0]?.type;
	const spaceId = labelData?.content?.nodes?.[0]?.space?.id || '';
	// this will be used to disable add/delete if the user doesn't have page edit permissions
	const isDisabled = !labelData?.content?.nodes?.[0]?.operations?.find((item) => {
		return item?.operation === 'update' && item?.targetType === type;
	});

	useEffect(() => {
		!(labelsQueryLoading || ssrLabelsQueryLoading) &&
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'rendered',
					actionSubject: 'labels',
					containerId: spaceId,
					containerType: 'space',
					objectId: contentId,
					objectType: type,
					source,
					attributes: {
						count: {
							global: filterByPrefix(selectedOptions, 'global').length,
							personal: filterByPrefix(selectedOptions, 'my').length,
							total: selectedOptions.length,
						},
						...(isLivePage ? { isLivePage } : null),
					},
				},
			}).fire();
	}, [
		labelsQueryLoading,
		ssrLabelsQueryLoading,
		selectedOptions,
		spaceId,
		contentId,
		type,
		source,
		createAnalyticsEvent,
		isLivePage,
	]);

	const [addLabel, { error: addError }] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		LabelsSelectAddLabelsMutation,
		{
			update(cache, { data: { addLabels } }) {
				const cachedData = cache.readQuery<LabelsQueryType, LabelsQueryVariables>({
					query: LabelsQuery,
					variables: {
						contentId,
						refetchQuery: false,
						status: ALLOWED_CONTENT_STATUSES,
					},
				});
				if (cachedData?.content?.nodes?.[0] && addLabels?.labels) {
					const newLabels = unionBy(
						cachedData.content.nodes[0].labels?.nodes,
						addLabels.labels.nodes,
						'id',
					);
					cachedData.content.nodes[0].labels = {
						count: newLabels.length,
						nodes: newLabels,
						// @ts-ignore
						__typename: 'PaginatedLabelList',
					};
					cache.writeQuery({
						query: LabelsQuery,
						variables: {
							contentId,
							refetchQuery: false,
							status: ALLOWED_CONTENT_STATUSES,
						},
						data: cachedData,
					});
				}
			},
			onCompleted: () => {
				if (onUpdateSuccess) {
					onUpdateSuccess();
				}

				if (onAdded) {
					onAdded();
				}
			},
		},
	);

	const handleDeleteLabelUpdate: MutationUpdaterFn<DeleteLabelMutation> = useCallback(
		(cache, { data }) => {
			const cachedData = cache.readQuery<LabelsQueryType, LabelsQueryVariables>({
				query: LabelsQuery,
				variables: {
					contentId,
					refetchQuery: false,
					status: ALLOWED_CONTENT_STATUSES,
				},
			});

			if (
				cachedData?.content?.nodes?.[0]?.labels?.nodes &&
				cachedData?.content?.nodes?.[0]?.labels?.count != null
			) {
				// TODO: label is not a unique identifier. Either prefix + label or label id should be used instead, but neither are returned by the mutation
				const filteredCachedLabels = cachedData.content.nodes[0].labels.nodes.filter(
					(node) => node?.label !== data?.deleteLabel?.label,
				);
				cache.writeQuery<LabelsQueryType, LabelsQueryVariables>({
					query: LabelsQuery,
					variables: {
						contentId,
						refetchQuery: false,
						status: ALLOWED_CONTENT_STATUSES,
					},
					data: {
						content: {
							nodes: [
								{
									...cachedData.content.nodes[0],
									labels: {
										nodes: filteredCachedLabels,
										count: filteredCachedLabels.length,
										// @ts-ignore
										__typename: 'PaginatedLabelList',
									},
								},
							],
							__typename: 'PaginatedContentListWithChild',
						},
					},
				});
			}
		},
		[contentId],
	);

	const [deleteLabel, { error: rawDeleteError }] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		LabelsSelectDeleteLabelsMutation,
		{
			update: handleDeleteLabelUpdate,
			onCompleted: () => {
				if (onUpdateSuccess) {
					onUpdateSuccess();
				}
			},
		},
	);
	const deleteError = !isLabelNotFoundError(rawDeleteError) ? rawDeleteError : undefined;

	const [
		searchQuery,
		{
			data: searchLabelQueryResults,
			loading: searchLabelQueryLoading,
			error: searchLabelQueryError,
		},
	] = useLazyQuery<LabelSearchQueryType, LabelSearchQueryVariables>(LabelsSelectSearchQuery, {
		onCompleted: () => {
			experienceTracker.succeed({
				name: LABELS_SEARCH_LABEL_EXPERIENCE,
			});
		},
		onError: (error) => {
			if (isLabelSearch403Error(error)) {
				markErrorAsHandled(error);
				experienceTracker.abort({
					name: LABELS_SEARCH_LABEL_EXPERIENCE,
					reason: 'User was logged out',
				});
			}
			experienceTracker.fail({
				error,
				name: LABELS_SEARCH_LABEL_EXPERIENCE,
			});
		},
	});

	const [
		recommendedLabelsQuery,
		{ data: recommendedLabelsQueryResults, loading: recommendedLabelsQueryLoading },
	] = useLazyQuery<RecommendedLabelsQueryType, RecommendedLabelsQueryVariables>(
		RecommendedLabelsQuery,
		{
			variables: {
				contentId,
				spaceId,
			},
			onCompleted: (data) => {
				experienceTracker.succeed({
					name: LABELS_RECOMMENDED_EXPERIENCE,
				});
				const recommendedLabels = getRecommendedLabelsFromData(data);
				recommendedLabels &&
					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							action: 'shown',
							actionSubject: 'labelRecommendations',
							source,
							containerId: spaceId,
							containerType: 'space',
							objectId: contentId,
							objectType: type,
							attributes: {
								count: recommendedLabels?.length,
							},
						},
					}).fire();
			},
			onError: (error) => {
				experienceTracker.fail({
					error,
					name: LABELS_RECOMMENDED_EXPERIENCE,
				});
			},
		},
	);

	const fetchRecommendedLabels = useCallback(() => {
		if (type === 'page' && !isDisabled) {
			experienceTracker.start({
				name: LABELS_RECOMMENDED_EXPERIENCE,
			});
			recommendedLabelsQuery();
		}
	}, [type, experienceTracker, recommendedLabelsQuery, isDisabled]);

	const recommendedLabels = getRecommendedLabelsFromData(recommendedLabelsQueryResults);
	const options = useOptions({
		searchLabelQueryResults,
		recommendedLabelsQueryResults,
		hasInput: Boolean(inputValue),
	});

	const debouncedSearchQuery = useMemo(
		() => debounce(searchQuery, 150, { leading: true }),
		[searchQuery],
	);

	const handleInputChange = (inputValue) => {
		const error = getValidationError(inputValue);
		setValidationError(error);
		setInputValue(sanitizeInputValue(inputValue));
		if (Boolean(inputValue)) {
			experienceTracker.start({ name: LABELS_SEARCH_LABEL_EXPERIENCE });
			if (!validationError) {
				debouncedSearchQuery({
					variables: {
						searchText: inputValue,
						contentId,
					},
				});
			}
		} else {
			fetchRecommendedLabels();
		}
	};

	const filterOption = ({ value }) => {
		return (
			!searchLabelQueryError &&
			createFilter({}) &&
			!isStarredLabel(value) &&
			!getValidationError(value)
		);
	};

	const fireLabelChangeEvent = useCallback(
		({ action, labels }: any) => {
			labels.map(({ id: labelId, name: labelName, prefix: labelPrefix, strategy, subStrategy }) => {
				const recommendedIndex = recommendedLabels?.findIndex(({ id }) => id === labelId);
				let recommendedRanking;
				if (recommendedIndex >= 0) {
					recommendedRanking = recommendedIndex;
					if (strategy?.startsWith('searched')) {
						strategy = 'recommended_searched';
						subStrategy = recommendedLabels[recommendedIndex].strategy;
					}
				}
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action,
						actionSubject: 'label',
						source,
						objectType: type,
						objectId: contentId,
						containerType: 'space',
						containerId: spaceId,
						attributes: {
							contentId,
							labelId,
							strategy,
							subStrategy,
							isNewLabel: strategy === 'newly_created',
							privacySafeLabelPrefix: getPrivacySafePrefix(labelPrefix),
							activationId,
							recommendedCount: recommendedLabels?.length,
							hasRecommendations: !!recommendedLabels?.length,
							recommendedRanking,
							...(isLivePage ? { isLivePage } : null),
						},
						nonPrivacySafeAttributes: {
							labelName,
							labelPrefix,
						},
					},
				}).fire();
			});
		},
		[
			type,
			source,
			contentId,
			spaceId,
			createAnalyticsEvent,
			activationId,
			recommendedLabels,
			isLivePage,
		],
	);

	const handleSelectLabel = useCallback(
		async (option: any) => {
			experienceTracker.start({
				name: LABELS_ADD_LABEL_EXPERIENCE,
			});
			try {
				await addLabel({
					variables: {
						labels: [{ name: option.name, prefix: option.prefix }],
						contentId,
					},
					optimisticResponse: {
						addLabels: {
							__typename: 'AddLabelsPayload',
							labels: {
								__typename: 'PaginatedLabelList',
								count: (labelData?.content?.nodes?.[0]?.labels?.count || 0) + 1,
								nodes: [
									...(labelData?.content?.nodes?.[0]?.labels?.nodes || []),
									{
										__typename: 'Label',
										id: option.id,
										prefix: option.prefix,
										name: option.name,
										label: option.name,
									},
								],
							},
						},
					},
				});
				experienceTracker.succeed({ name: LABELS_ADD_LABEL_EXPERIENCE });
				fireLabelChangeEvent({
					action: 'add',
					labels: [option],
				});
			} catch (error) {
				experienceTracker.fail({
					error,
					name: LABELS_ADD_LABEL_EXPERIENCE,
				});
			}
		},
		[experienceTracker, addLabel, labelData, fireLabelChangeEvent, contentId],
	);

	const handleCreateLabel = useCallback(
		async (option: any) => {
			experienceTracker.start({
				name: LABELS_CREATE_LABEL_EXPERIENCE,
			});
			try {
				const { data } = await addLabel({
					variables: {
						labels: [{ name: option.value, prefix: '' }],
						contentId,
					},
					optimisticResponse: {
						addLabels: {
							__typename: 'AddLabelsPayload',
							labels: {
								__typename: 'PaginatedLabelList',
								count: (labelData?.content?.nodes?.[0]?.labels?.count || 0) + 1,
								nodes: [
									...(labelData?.content?.nodes?.[0]?.labels?.nodes || []),
									{
										name: option.value,
										prefix: '',
										__typename: 'Label',
										id: uuid(),
										label: option.value,
									},
								],
							},
						},
					},
				});
				experienceTracker.succeed({ name: LABELS_CREATE_LABEL_EXPERIENCE });
				fireLabelChangeEvent({
					action: 'add',
					// filter out the newly added labels from the already existing - this is necessary in order to get the new label ids to send in the analytics event
					labels: (data?.addLabels?.labels?.nodes || [])
						.map(makeTransformLabelToOption('newly_created'))
						.filter(({ value }) => option.value === value),
				});
			} catch (error) {
				experienceTracker.fail({
					error,
					name: LABELS_CREATE_LABEL_EXPERIENCE,
				});
			}
		},
		[experienceTracker, addLabel, labelData, fireLabelChangeEvent, contentId],
	);

	const handleDeleteLabel = useCallback(
		async (removedValue: any) => {
			const optimisticResponse = {
				deleteLabel: {
					__typename: 'DeleteLabelPayload',
					contentId,
					label: removedValue?.name,
				},
			};

			if (!removedValue) return;
			experienceTracker.start({
				name: LABELS_DELETE_LABEL_EXPERIENCE,
			});
			try {
				await deleteLabel({
					variables: {
						label: removedValue.name,
						contentId,
					},
					optimisticResponse,
				});
				experienceTracker.succeed({ name: LABELS_DELETE_LABEL_EXPERIENCE });
				fireLabelChangeEvent({
					action: 'deleted',
					labels: [removedValue],
				});
			} catch (error) {
				if (isLabelNotFoundError(error)) {
					markErrorAsHandled(error);
					handleDeleteLabelUpdate(getApolloClient(), {
						data: optimisticResponse,
					});
					experienceTracker.abort({
						name: LABELS_DELETE_LABEL_EXPERIENCE,
						reason: 'label already deleted',
					});
				} else if (isContentNotFoundError(error)) {
					markErrorAsHandled(error);
					experienceTracker.abort({
						name: LABELS_DELETE_LABEL_EXPERIENCE,
						reason: 'Content was deleted',
					});
				} else if (isLabelDeletePermissionsError(error)) {
					markErrorAsHandled(error);
					experienceTracker.abort({
						name: LABELS_DELETE_LABEL_EXPERIENCE,
						reason: 'Edit permissions were removed',
					});
				} else if (isLabelMutationLoggedOutError(error)) {
					markErrorAsHandled(error);
					experienceTracker.abort({
						name: LABELS_DELETE_LABEL_EXPERIENCE,
						reason: 'User was logged out',
					});
				} else {
					experienceTracker.fail({
						error,
						name: LABELS_DELETE_LABEL_EXPERIENCE,
					});
				}
			}
		},
		[experienceTracker, deleteLabel, fireLabelChangeEvent, contentId, handleDeleteLabelUpdate],
	);

	const handleLabelChange = async (_, { action, option, removedValue }) => {
		if (action === ActionTypes.SELECT_OPTION) {
			void handleSelectLabel(option);
		} else if (action === ActionTypes.CREATE_OPTION) {
			void handleCreateLabel(option);
		} else if (action === ActionTypes.REMOVE_VALUE || action === ActionTypes.POP_VALUE) {
			void handleDeleteLabel(removedValue);
		}
	};

	const handleMenuOpen = useCallback(() => {
		fetchRecommendedLabels();
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'open',
				actionSubject: 'labelsSelect',
				source,
				containerId: spaceId,
				containerType: 'space',
				objectId: contentId,
				objectType: type,
			},
		}).fire();
	}, [source, spaceId, contentId, type, createAnalyticsEvent, fetchRecommendedLabels]);

	const isLoading = searchLabelQueryLoading || recommendedLabelsQueryLoading;

	return {
		options: isLoading ? [] : options,
		value: !(labelsQueryLoading || ssrLabelsQueryLoading) ? selectedOptions : [],
		filterOption,
		onChange: handleLabelChange,
		onMenuOpen: handleMenuOpen,
		onInputChange: handleInputChange,
		inputValue,
		isLoading,
		isDisabled,
		labelsQueryLoading: labelsQueryLoading || ssrLabelsQueryLoading,
		labelsQueryError: labelsQueryError || ssrLabelsQueryError,
		labelsMutationError: addError || deleteError,
		labelsSearchQueryError: searchLabelQueryError,
		validationError,
		fetchRecommendedLabels,
	};
};
