import { mergeLiveDocI18n } from '@confluence/live-pages-utils/entry-points/mergeLiveDocI18n';
import type { FC } from 'react';
import React, { forwardRef } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import type { OptionType, SelectProps } from '@atlaskit/select';

import { isStarredLabel, useLabelsSelectProps } from './useLabelsSelectProps';
import { LabelsSelectComponent } from './LabelsSelectComponent';

const i18nBase = defineMessages({
	labelAlreadyAddedMessage: {
		id: 'labels.labels-select.create-new-label-already-added',
		defaultMessage: '{inputValue} was already added to the page',
		description:
			'Text that appears in labels select when there are no options to select because user already added the label they searched for.',
	},
	labelSearchErrorMessage: {
		id: 'labels.labels-select.labels-search-error',
		defaultMessage: 'Suggestions aren’t loading. Try writing it again or refresh.',
		description: 'Text that appears in labels select when there is an error searching for labels',
	},
});

const i18nLiveDocs = defineMessages({
	labelAlreadyAddedMessage: {
		id: 'labels.labels-select.create-new-label-already-added.livedocs',
		defaultMessage: '{inputValue} was already added ',
		description: 'Updated version of labels.labels-select.create-new-label-already-added',
	},
});

const i18n = mergeLiveDocI18n(i18nBase, i18nLiveDocs);
export interface CustomOptionType extends OptionType {
	name: string;
	prefix: string;
	strategy: string;
	subStrategy?: string[];
}

type LabelsSelectProps = SelectProps<CustomOptionType, true> & {
	contentId: string;
	source: string;
	spaceKey: string | undefined;
	experienceName: string;
	onUpdateSuccess?: () => void;
};

export const LabelsSelect: FC<LabelsSelectProps> = forwardRef(
	(
		{
			contentId,
			source,
			experienceName,
			components,
			styles,
			spaceKey,
			//@ts-ignore react-select types
			isInDialog,
			onUpdateSuccess,
			//@ts-ignore react-select types
			onAdded,
			...customProps
		},
		ref,
	) => {
		const {
			isDisabled,
			labelsQueryLoading,
			labelsQueryError,
			labelsMutationError,
			labelsSearchQueryError,
			validationError,
			fetchRecommendedLabels,
			...controlledProps
		} = useLabelsSelectProps({
			contentId,
			source,
			onUpdateSuccess,
			onAdded,
		});

		const intl = useIntl();
		const getNoOptionsMessage = (inputValue) => {
			if (!inputValue || isStarredLabel(inputValue) || validationError) {
				return null;
			}
			if (labelsSearchQueryError) {
				return intl.formatMessage(i18n.labelSearchErrorMessage);
			}
			return intl.formatMessage(i18n.labelAlreadyAddedMessage, {
				inputValue,
			});
		};
		return (
			<LabelsSelectComponent
				isDisabled={isDisabled}
				labelsQueryLoading={labelsQueryLoading}
				labelsQueryError={labelsQueryError}
				labelsMutationError={labelsMutationError}
				validationError={validationError}
				fetchRecommendedLabels={fetchRecommendedLabels}
				experienceName={experienceName}
				isInDialog={isInDialog}
				ref={ref}
				spaceKey={spaceKey}
				source={source}
				styles={styles}
				customProps={customProps}
				controlledProps={controlledProps}
				components={components}
				getNoOptionsMessage={getNoOptionsMessage}
			/>
		);
	},
) as FC<LabelsSelectProps>;
